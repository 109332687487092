import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { useCart } from '../lib/CartContext'
import SEO from '../components/Seo'
import Carousel from '../components/ProductCarousel'
import Img from 'gatsby-image'
import '../assets/product.style.css'

const ShowProductInfo = ({ product, cores }) => {
  const cart = useCart()
  const addToCart = () => {
    cart.addToCart(product, 1)
    if (typeof window !== `undefined`) window.location.replace(`/checkout`)
  }
  return (
    <div className='container flex flex-col lg:flex-row'>
      <div className='w-full order-1 px-4 pb-4 lg:order-2 lg:m-4'>
        {/* TODO: Implementar o zoom na imagem selecionada */}
        <Carousel images={product.images} product={product.product} />
        <div className='text-center w-full order-2 pt-1 pb-4'>
          <button className='bg-transparent hover:bg-gray-700 text-gray-900 font-semibold hover:text-white py-2 px-4 border border-gray-700 hover:border-transparent' onClick={addToCart}>SOLICITAR ORÇAMENTO</button>
        </div>
      </div>
      <div className='flex flex-wrap order-2 pt-2 m-4 p-4 border border-gray-500 lg:order-1 lg:w-3/4 text-justify h-auto'>
        <div>
          <p className='text-lg font-bold pb-1 pt-4'>Descrição</p>
          <p className='text-lg'>{product.description.description}</p>
        </div>
        <div className='flex flex-col sm:flex-row w-full mt-2'>
          { product.cores &&
            <div className='w-1/4'>
              <p className='text-lg font-bold pb-1 pt-4'>Cores</p>
              <Img className='h-18 w-10 ml-2' fluid={cores.edges[0].node.childImageSharp.fluid} />
            </div>
          }
          { product.medidas &&
            <div className='w-3/4'>
              <p className='font-bold pb-1 pt-4'>Medidas</p>
              <div className='tableInner text-xs md:text-base' dangerouslySetInnerHTML={{ __html:product.medidas.childMarkdownRemark.html }} />
            </div>
          }
        </div>
        <div className='mt-2'>
          <p className='text-lg font-bold pb-1 pt-4'>Acessórios</p>
          <p className='text-lg'>Consulte nossa linha de acessórios para informações.</p>
        </div>
      </div>
    </div>
  )
}

const Product = ({ data }) => {
  const { product, cores } = data
  return (
    <Layout>
      <SEO />
      <h1 className='invisible'>{product.product}</h1>
      <ShowProductInfo product={product} cores={cores} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    product: contentfulProduct(slug: {eq: $slug}) {
      product
      slug
      cores
      medidas {
        childMarkdownRemark {
          html
        }
      }
      description {
        description
      }
      images {
        description
        fluid(maxHeight: 600) {
          ...GatsbyContentfulFluid
        }
      }
    }
    cores: allFile(filter: {relativePath: {eq: "cores.png"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 608) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default Product