import React from 'react'
import { useEmblaCarousel } from 'embla-carousel-react'
import Img from 'gatsby-image'
import '../../assets/product.style.css'

const ProductCarousel = ({ images, product }) => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    dragFree: false,
    slidesToScroll: 1,
    loop: false
  })

  return (
    <>
      <EmblaCarouselReact>
        <div style={{ display: 'flex' }}>
          {
            images.map((image, i) => {
              return (
                <div key={i} style={{ flex: '0 0 100%' }}>
                  <Img loading='auto' className='shadow m-1 bg-white imagem' alt={image.description} fluid={image.fluid} />
                </div>
              )
            })
          }
        </div>
      </EmblaCarouselReact>
      <div className='flex flex-row justify-around text-4xl'>
        <button className={'passa sm:mt-2 border border-white pb-1 '+ (images.length < 2 ? 'hidden' : '')} onClick={() => embla.scrollPrev()}>{'<'}</button>
        <h2 className='robika nomezao text-base sm:text-2xl font-bold border border-white mt-5 pb-1'>{product}</h2>
        <button className={'passa sm:mt-2 border border-white pb-1 '+ (images.length < 2 ? 'hidden' : '')} onClick={() => embla.scrollNext()}>{'>'}</button>
      </div>
    </>
  )
}

export default ProductCarousel
